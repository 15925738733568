import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/cookie-policy/Content";

const CookiePolicy = () => {
  return (
    <Layout sticky_header>
      <Content />
    </Layout>
  );
};

export const Head = () => (
  <Seo title="Polityka prywatności | Kameralna Dolina" />
);

export default CookiePolicy;
